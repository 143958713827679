export const user = {
  list: '用户列表',
  from: '用户操作',
  view: '用户详情',

  create: '添加用户',
  update: '修改用户',
  delete: '删除用户',
  export: '导出用户',

  reset: '重置密码',
  change: '修改密码',
  permission: '用户权限',
  apply_info: '申请',

  nickname: '用户姓名',
  avatar: '用户头像',
  sex: '用户性别',
  account: '登录账户',
  username: '手机号码',
  realname: '真实名称',
  email: '联系邮箱',
  mobile: '联系号码',
  status: '用户状态',
  is_bind: '绑定状态',
  qrcode_url: '绑定二维码',
  user_role_name: '用户角色',
  last_login_time: '最后登录时间',
  create_time: '注册时间',
  role_id: '用户角色',

  position: '职务',
  service_declaration: '服务宣言',
  client_name: '服务客户',

  please_mobile: '请使用手机号作为用户账号',

  rules: {
    username: {
      require: '登录账户不能为空',
      length: '长度在 1 到 32 个字符',
    },
    nickname: {
      require: '用户昵称不能为空',
      length: '长度在 1 到 32 个字符',
    },
    email: {
      format: '邮箱格式错误',
    },
    mobile: {
      format: '手机号码格式错误',
    },
    avatar: {
      require: '用户头像不能为空',
    },
    role_id: {
      require: '角色不能为空',
    },
    position: {
      require: '职务不能为空',
    },
  },

  password: {
    from: '密码修改',

    old_password: '当前密码',
    password: '新密码',
    password_confirmation: '确认密码',
    please_old_password: '请输入当前登录密码',
    rules: {
      old_password: {
        require: '当前登录密码不能为空',
        length: '长度在 1 到 32 个字符',
      },
      password: {
        require: '新密码不能为空',
        length: '长度在 1 到 32 个字符',
      },
      password_confirmation: {
        require: '确认密码不能为空',
        length: '长度在 1 到 32 个字符',
      },
    },
  },

  center: {
    title: '用户信息',
    description: '用户描述',

    account: {
      title: '个人信息',
    },
    password: {
      title: '个人密码',
    },
    login: {
      title: '登录记录',

      browser: '浏览器',
      system: '登录系统',
      location: '登录地点',
      ip: 'IP',
    },
  },

  role: {
    'title': '用户角色'
  },

  bind: {
    from: '绑定操作',
    info: '用户绑定',

    cancel_bind: '解除绑定',
  },

  apply: {
    create_time: '申请时间',
  }
}
