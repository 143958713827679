export const activity = {
  list: '活动单列表',
  from: '活动单操作',
  view: '活动单详情',

  client_id: '所属客户',
  contact_id: '所属联系人',
  title: '活动单标题',
  content: '活动单内容',
  end_time: '结束日期',

  rules: {
    client_id: {
      require: '所属客户不能为空',
    },
    contact_id: {
      require: '所属联系人不能为空',
    },
    title: {
      require: '活动单标题不能为空',
    },
    end_time: {
      require: '结束日期不能为空',
    },
  },
}
