export const demand = {
  list: '需求列表',
  from: '需求操作',
  view: '需求详情',

  basic_info: '基本信息',
  user_info: '归属人信息',

  info: '需求信息',

  client_id: '需求客户',
  demand_type: '需求类型',
  demand_time: '需求时间',
  contact_id: '联系人',
  money: '预估利润',
  description: '需求描述',
  demand_status: '需求状态',
  salesperson: '需求归属人',
  client_contact: '客户/联系人',
  remark: '丢单原因',
  lose: '需求丢单',

  rules: {
    client_id: {
      require: '需求客户不能为空',
    },
    demand_type: {
      require: '需求类型不能为空',
    },
    demand_time: {
      require: '需求时间不能为空',
    },
    contact_id: {
      require: '联系人不能为空',
    },
    money: {
      require: '预估利润不能为空',
    },
    description: {
      require: '需求描述不能为空',
    },
    remark: {
      require: '丢单原因不能为空',
    },
  },
}
