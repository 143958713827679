export const product = {
  list: '产品列表',
  from: '产品操作',
  view: '产品详情',
  info: '产品信息',

  basic_info: '基本信息',
  detail_info: '详细信息',
  example_info: '案例信息',
  resource_info: '产品附件',

  type: '产品类型',
  title: '产品名称',
  short_title: '副标题',
  picture: '产品封面',
  module_id: '产品模块',
  category_id: '产品分类',
  label_id: '产品标签',
  resource: '产品附件',
  description: '产品介绍',
  feature: '产品特色',
  content: '详细内容',
  example: '案例内容',
  place_total: '关联基地',
  teacher_total: '关联讲师',
  video: '产品视频',
  scheme: '产品方案',
  create_time: '添加时间',
  is_recommend: '首页推荐',
  is_hidden: '展示状态',
  hot: '热度',
  score: '评分',
  status: '产品状态',
  preview: '产品预览',

  rules: {
    module_id: {
      require: '产品模块不能为空',
    },
    category_id: {
      require: '产品分类不能为空',
    },
    type: {
      require: '产品类型不能为空',
    },
    title: {
      require: '产品标题不能为空',
    },
    picture: {
      require: '产品封面不能为空',
    },
    description: {
      require: '产品介绍不能为空',
    },
  },

  module: {
    list: '模块列表',
    from: '模块操作',
    view: '模块详情',
    info: '模块',

    title: '标题',
    short_title: '副标题',
    picture: '选中图片',
    picture_unselected: '未选中图片',
    color: '副标题颜色',

    rules: {
      title: {
        require: '产品模块标题不能为空',
        length: '产品模块标题最大长度为20',
      },
      short_title: {
        require: '产品模块副标题不能为空',
        length: '产品模块副标题最大长度为20',
      },
      picture: {
        require: '产品模块选中图片不能为空',
      },
      picture_unselected: {
        require: '产品模块未选中图片不能为空',
      },
      color: {
        require: '产品模块副标题颜色不能为空',
      },
    }
  },

  category: {
    list: '分类列表',
    from: '分类操作',
    view: '分类详情',
    info: '分类',

    parent_title: '分类模块',
    title: '分类标题',

    rules: {
      title: {
        require: '分类标题不能为空',
        length: '分类标题最大长度为50',
      },
    }
  },


  label: {
    list: '标签列表',
    from: '标签操作',
    view: '标签详情',
    info: '标签',

    title: '标签标题',

    rules: {
      title: {
        require: '标签标题不能为空',
        length: '标签标题最大长度为50',
      },
    }
  },


  teacher: {
    list: '讲师列表',
    from: '讲师操作',
    view: '讲师详情',
    info: '产品讲师',

    rules: {
      teacher_id: {
        require: '讲师不能为空',
      },
    }
  },


  place: {
    list: '基地列表',
    from: '基地操作',
    view: '基地详情',
    info: '产品基地',

    rules: {
      place_id: {
        require: '基地不能为空',
      },
    }
  },

  resource: {
    list: '附件列表',
    download: '下载',

    name: '附件名称',
  },
}
