export const organization = {
  list: '机构列表',
  from: '机构操作',
  view: '机构详情',
  export: '导出机构',
  info: '机构信息',
  contact_info: '联系人信息',

  organization_no: '机构编号',
  logo: '机构Logo',
  title: '机构名称',
  create_time: '注册时间',

  business_license: '营业执照',
  manager_name: '机构法人姓名',
  mobile: '机构电话',
  address: '机构地址',

  contact_name: '联系人姓名',
  contact_mobile: '联系人电话',
  contact_email: '联系人邮箱',
  contact_weixin: '联系人微信',
  contact_qq: '联系人QQ',

  squad_total: '机构创建班级数量',
  member_total: '机构注册会员数量',
  course_total: '机构购买课程数量',

  certification_status: '认证状态',
  wait_certification: '待认证',
  certification_pass: '认证通过',
  certification_unpass: '认证不通过',

  course_title: '课程',
  course_info: '课程信息',
  open_squad_info: '所开班级',
  graduation_squad_info: '结业班级',



  rules: {
    logo: {
      require: '机构Logo不能为空',
    },
    title: {
      require: '机构名称不能为空',
    },
    manager_name: {
      require: '机构法人姓名不能为空',
    },
    address: {
      require: '机构地址不能为空',
    },
  },

  audit: {
    title: '机构审核',
    content: '审核意见',

    rules: {
      audit_status: {
        require: '审核状态不能为空',
      }
    }
  },

  certification: {
    title: '机构认证',
    content: '认证意见',

    rules: {
      certification_status: {
        require: '认证状态不能为空',
      }
    }
  },

  course: {
    list: '机构课程列表',
    from: '机构课程操作',
    view: '机构课程详情',

    create_time: '添加时间',

    rules: {
      course_id: {
        require: '请选择课程',
      },
    },
  },
}
