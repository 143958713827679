export const browse = {
  list: '浏览记录列表',
  from: '浏览记录操作',
  view: '浏览记录详情',
  info: '详情',

  browse_name: '浏览用户',
  name_type: '用户类型',
  module: '浏览内容',
  title: '内容标题',
  create_time: '浏览时间',

  duration: '访问时长',
  total: '访问次数',

  category: {
    group: '浏览日期',
  }
}
