export const example = {
  list: '案例列表',
  from: '案例操作',
  view: '案例详情',
  info: '案例信息',

  basic_info: '基本信息',
  detail_info: '详细信息',
  explain_info: '案例说明',

  year: '案例年份',
  type: '案例类型',
  title: '案例名称',
  picture: '案例封面',
  category_id: '案例分类',
  industry_id: '客户行业',
  region_id: '案例地点',
  label_id: '案例标签',
  picture: '案例封面',
  content: '详细内容',
  address: '活动地区',
  customer_type: '客户性质',
  logo: '客户LOGO',
  activity_total: '活动人数',
  activity_time: '活动时间',
  activity_course: '活动行程',
  create_time: '添加时间',
  remark: '备注',
  is_recommend: '首页推荐',
  status: '案例状态',
  time_address: '活动时间/地区',
  category_industry: '案例分类/行业',
  people_level: '参与人数',
  preview: '案例预览',

  rules: {
    title: {
      require: '案例名称不能为空',
    },
    picture: {
      require: '案例封面不能为空',
    },
    category_id: {
      require: '案例分类不能为空',
    },
    industry_id: {
      require: '客户行业不能为空',
    },
    customer_type: {
      require: '客户性质不能为空',
    },
    activity_total: {
      require: '活动人数不能为空',
    },
    activity_time: {
      require: '活动时间不能为空',
    },
    activity_course: {
      require: '活动行程不能为空',
    },
    address: {
      require: '活动地区不能为空',
    },
  },

  category: {
    list: '分类列表',
    from: '分类操作',
    view: '分类详情',
    info: '分类',

    title: '分类标题',

    rules: {
      title: {
        require: '分类标题不能为空',
        length: '分类标题最大长度为50',
      },
    }
  },


  label: {
    list: '标签列表',
    from: '标签操作',
    view: '标签详情',
    info: '标签',

    title: '标签标题',

    rules: {
      title: {
        require: '标签标题不能为空',
        length: '标签标题最大长度为50',
      },
    }
  },


  explain: {
    list: '说明列表',
    from: '说明操作',
    view: '说明详情',
    info: '案例说明',

    title: '标题',
    content: '内容',

    rules: {
      title: {
        require: '标题不能为空',
        length: '标题最大长度为100',
      },
      content: {
        require: '内容不能为空',
        length: '内容最大长度为500',
      },
    }
  },
}
