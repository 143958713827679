export const wechat = {

  setting: {
    center: '设置中心',
  },

  navigation: {
    list: '首页导航列表',
    from: '首页导航操作',

    title: '首页导航标题',
    picture: '首页导航图片',
    url: '首页导航地址',
    is_visitor: '游客可见',

    rules: {
      title: {
        require: '首页导航标题不能为空',
        length: '首页导航标题最大长度为20',
      },
      url: {
        require: '首页导航地址不能为空',
      },
      picture: {
        require: '首页导航图片不能为空',
      },
    }
  },

  company: {
    from: '公司信息操作',

    content: '公司信息',
  },

  config: {
    center: '系统配置',
  },

  product: {
    product_id: '热门产品',

    rules: {
      product_id: {
        require: '热门产品不能为空',
      }
    }
  },

  example: {
    example_id: '热门案例',

    rules: {
      example_id: {
        require: '热门案例不能为空',
      }
    }
  },
}
