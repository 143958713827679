export const share = {
  list: '分享记录列表',
  from: '分享记录操作',
  view: '分享记录详情',
  info: '详情',

  product_info: '产品',
  example_info: '案例',
  place_info: '基地',
  teacher_info: '讲师',
  supplier_info: '供应商',


  share_name: '分享用户',
  module: '分享内容',
  title: '内容标题',
  create_time: '分享时间',

  total: '访问次数',

  category: {
    group: '分享日期',
  }
}
