/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-13
 *
 * 用户信息存储器
 */
export const getUserInfo = defineStore("getUserInfo", {
  state: () => ({
    userinfo: [],
  }),
  getters: {
    organization_id: state => state.userinfo.organization_id,
    user_id: state => state.userinfo.id,
    avatar: state => state.userinfo.avatar,
    nickname: state => state.userinfo.nickname,
  },
  actions: {
    setUserInfo(data) {
      this.userinfo = data;
    },
    clearData() {
      this.userinfo = []
    },
  },
  persist: {
    key: "userInfoStorage", // 持久化存入的key
    storage: localStorage // 持久化方式
  }
});

