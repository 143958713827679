import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'

import zhLocale from './zh'
import enLocale from './en'
import elementZhLocale from 'element-plus/es/locale/lang/zh-cn'
import elementEnLocale from 'element-plus/es/locale/lang/en'

const messages = {
  zh: {
    ...zhLocale,
    ...elementZhLocale,
  },
  en: {
    ...enLocale,
    ...elementEnLocale,
  },
}

/**
 * 获取当前系统使用语言字符串
 *
 * @returns zh-cn|en ...
 */
export const getLanguage = () => {
  // 本地缓存获取
  let language = localStorage.getItem('language');
  if (language) {
    return language;
  }
  // 浏览器使用语言
  language = navigator.language.toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return 'zh';
};

const i18n = createI18n({
  legacy: false, // 如果要支持 compositionAPI，此项必须设置为 false
  globalInjection: true, // 全局注册$t方法
  locale: 'zh', // 设置语言类型
  messages: messages
});

export default i18n;
