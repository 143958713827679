export const place = {
  list: '基地列表',
  from: '基地操作',
  view: '基地详情',
  info: '基地信息',

  basic_info: '基本信息',
  detail_info: '详细信息',
  resource_info: '基地附件',

  organization_id: '创建者',
  category_id: '基地分类',
  region_id: '所在区域',
  title: '基地名称',
  picture: '基地封面',
  address: '详细地址',
  accommodate_total: '容纳人数',
  stay_total: '留宿人数',
  conference_total: '会议人数',
  cooperation_level: '合作等级',
  star_level: '基地星级',
  remark: '备注',
  content: '详细内容',
  resource: '附件列表',
  create_time: '添加时间',
  is_recommend: '首页推荐',
  status: '基地状态',
  preview: '基地预览',

  rules: {
    category_id: {
      require: '基地分类不能为空',
    },
    title: {
      require: '基地名称不能为空',
    },
    picture: {
      require: '基地封面不能为空',
    },
    content: {
      require: '详细内容不能为空',
    },
    address: {
      require: '详细地址不能为空',
    },
    cooperation_level: {
      require: '合作等级不能为空',
    },
    star_level: {
      require: '基地星级不能为空',
    },
  },

  category: {
    list: '分类列表',
    from: '分类操作',
    view: '详情',
    info: '分类',

    title: '分类标题',
    picture: '封面图片',

    rules: {
      title: {
        require: '分类标题不能为空',
        length: '分类标题最大长度为50',
      },
    }
  },


  contact: {
    list: '联系人列表',
    from: '联系人操作',
    view: '联系人详情',
    info: '基地联系人',

    name: '联系人姓名',
    mobile: '联系电话',
    position: '职位/角色',

    rules: {
      name: {
        require: '联系人姓名不能为空',
        length: '联系人姓名最大长度为50',
      },
      mobile: {
        require: '联系电话不能为空',
        length: '联系电话最大长度为50',
      },
    }
  },



  photo: {
    list: '相册列表',
    from: '相册操作',
    view: '相册详情',
    info: '添加',

    picture: '相册图片',

    rules: {
      category_id: {
        require: '相册分类不能为空',
      },
      picture: {
        require: '相册图片不能为空',
      },
    },

    category: {
      list: '分类列表',
      from: '分类操作',
      view: '分类详情',
      info: '基地相册',

      title: '分类标题',

      rules: {
        title: {
          require: '分类标题不能为空',
          length: '分类标题最大长度为50',
        },
      }
    },
  },
}
