export const teacher = {
  list: '讲师列表',
  from: '讲师操作',
  view: '讲师详情',
  info: '讲师信息',

  basic_info: '基本信息',
  detail_info: '详细信息',
  resource_info: '讲师附件',

  organization_id: '创建者',
  category_id: '讲师分类',
  region_id: '所在区域',
  name: '讲师姓名',
  mobile: '讲师电话',
  picture: '讲师头像',
  start_year: '从业年份',
  address: '所属区域',
  cooperation_level: '合作等级',
  teacher_level: '带课级别',
  remark: '备注',
  content: '详细内容',
  resource: '附件列表',
  create_time: '添加时间',
  is_recommend: '首页推荐',
  start_limit: '从业年限',
  status: '讲师状态',
  preview: '讲师预览',
  close: '讲师复制',

  rules: {
    category_id: {
      require: '讲师分类不能为空',
    },
    name: {
      require: '讲师姓名不能为空',
    },
    mobile: {
      require: '讲师电话不能为空',
    },
    picture: {
      require: '讲师头像不能为空',
    },
    start_year: {
      require: '从业年份不能为空',
    },
    content: {
      require: '详细内容不能为空',
    },
    address: {
      require: '所属区域不能为空',
    },
    cooperation_level: {
      require: '合作等级不能为空',
    },
    teacher_level: {
      require: '带课级别不能为空',
    },
  },

  category: {
    list: '分类列表',
    from: '分类操作',
    view: '分类详情',
    info: '分类',

    title: '分类标题',

    rules: {
      title: {
        require: '分类标题不能为空',
        length: '分类标题最大长度为50',
      },
    }
  },
}
