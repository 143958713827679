// ---------------------------------------
// 核心

import { common } from './system/platform/common/zh'

import { login } from './system/platform/login/zh'

import { user } from './system/platform/user/zh'

import { setting } from './system/platform/setting/zh'

// ---------------------------------------
// 模块

import { organization } from './module/platform/organization/zh'

import { advertising } from './module/platform/advertising/zh'

import {industry} from './module/platform/industry/zh'

import {product} from './module/platform/business/product/zh'

import {example} from './module/platform/business/example/zh'

import {contact} from './module/platform/business/contact/zh'

import {place} from './module/platform/business/place/zh'

import {teacher} from './module/platform/business/teacher/zh'

import {supplier} from './module/platform/business/supplier/zh'

import {demand} from './module/platform/business/demand/zh'

import {client} from './module/platform/business/client/zh'

import {activity} from './module/platform/business/activity/zh'

import {browse} from './module/platform/business/browse/zh'

import {share} from './module/platform/business/share/zh'

import {customization} from './module/platform/business/customization/zh'

import {question} from './module/platform/business/question/zh'

import {knowledge} from './module/platform/knowledge/zh'

import {wechat} from './module/platform/wechat/zh'


export default {
  // 公共
  common: {
    ...common,
  },

  // 登录
  login: {
    ...login,
  },

  // 用户
  user: {
    ...user,
  },

  // 设置
  setting: {
    ...setting,
  },

  // ------------------------------------

  // 广告
  advertising: {
    ...advertising,
  },

  // 机构
  organization: {
    ...organization,
  },

  // 产品
  product: {
    ...product,
  },

  // 案例
  example: {
    ...example,
  },

  // 联系人
  contact: {
    ...contact
  },

  // 基地
  place: {
    ...place
  },

  // 讲师
  teacher: {
    ...teacher
  },

  // 供应商
  supplier: {
    ...supplier
  },

  // 行业
  industry: {
    ...industry
  },

  // 需求
  demand: {
    ...demand
  },

  // 客户
  client: {
    ...client
  },

  // 活动单
  activity: {
    ...activity
  },

  // 定制服务
  customization: {
    ...customization
  },

  // 调查问卷试题
  question: {
    ...question
  },

  // 浏览记录
  browse: {
    ...browse
  },

  // 分享记录
  share: {
    ...share
  },

  // 知识库
  knowledge: {
    ...knowledge
  },

  // 微信
  wechat: {
    ...wechat
  }
}
