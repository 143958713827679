export const customization = {
  list: '专属定制列表',
  from: '专属定制操作',
  view: '专属定制详情',

  start_time: '团建时间',
  total: '团建人数',
  duration: '团建时长',
  mobile: '手机号码',
}
