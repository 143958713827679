export const contact = {
  list: '联系人列表',
  from: '联系人操作',
  view: '联系人详情',
  info: '联系人信息',

  basic_info: '基本信息',
  browse_info: '访问记录',

  name: '姓名',
  sex: '性别',
  mobile: '电话',
  position: '职位',
  brithday: '生日',
  working_hours: '工作年限',
  marriage_state: '婚姻',
  child_total: '子女',
  is_principal: '负责人',
  is_policymaker: '决策人',
  salesperson: '销售负责人',
  relation: '客情关系',
  interest: '爱好',
  value_concept: '价值观',
  remark: '备注',
  create_time: '加入时间',
  access_authority: '访问权限',
  user_id: '归属人',

  rules: {
    name: {
      require: '姓名不能为空',
    },
    sex: {
      require: '性别不能为空',
    },
    mobile: {
      require: '电话不能为空',
    },
  },
}
