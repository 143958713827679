export const industry = {
  list: '行业列表',
  from: '行业操作',
  view: '行业详情',

  parent_id: '上级行业',
  title: '行业名称',

  rules: {
    title: {
      require: '行业名称不能为空',
    },
  },
}
