// ---------------------------------------
// 核心

import { common } from './system/platform/common/en'

import { login } from './system/platform/login/en'

import { user } from './system/platform/user/en'

import { setting } from './system/platform/setting/en'

// ---------------------------------------
// 模块

export default {
  // 公共
  common: {
    ...common,
  },

  // 登录
  login: {
    ...login,
  },

  // 用户
  user: {
    ...user,
  },

  // 设置
  setting: {
    ...setting,
  },

}
