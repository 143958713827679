export const knowledge = {
  list: '知识列表',
  from: '知识操作',

  title: '知识标题',
  content: '知识内容',

  rules: {
    category_id: {
      require: '知识分类不能为空',
    },
    title: {
      require: '知识标题不能为空',
    },
  },

  // 知识分类
  category: {
    list: '知识分类列表',
    from: '知识分类操作',
    info: '分类',

    title: '知识分类标题',

    rules: {
      title: {
        require: '知识分类标题不能为空',
      },
    },
  },
}
